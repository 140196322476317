<template>
  <div class="col-12 p-0">
    <div
      class="d-flex d-md-none px-3 py-2 font-weight-bold font-14"
      v-if="participants.length === 0"
      style="
        border-bottom: 1px solid #4e4222 !important;
        border-radius: 0% !important;
      "
    >
      No participants
    </div>
    <div
      class="d-none d-md-flex px-4 ml-3 py-2 font-weight-bold font-14"
      v-if="participants.length === 0"
    >
      No participants
    </div>
    <div v-for="(participant, p) in participants" :key="p">
      <tr v-if="participant.ticket_id" class="d-none d-md-flex pl-3">
        <td class="col-3 py-2 px-4">
          {{ participant.first_name }}
        </td>
        <td class="col-lg-3 col-5 py-2 px-0">
          {{ participant.transaction_email }}
        </td>
        <td class="col-2 py-2 px-0">{{ participant.role }}</td>
        <td class="col-2 py-2 px-0">
          <span v-if="participant.checked_in"
            ><img
              :src="getIcon('green-dot.svg')"
              alt="green-icon"
              class="mr-1"
            />
            Checked-in</span
          >
          <span v-else
            ><img
              :src="getIcon('yellow-dot.svg')"
              alt="yellow icon"
              class="mr-1"
            />Reserved</span
          >
        </td>
        <div
          v-if="
            $store.state.role == 'admin' || $store.state.role == 'pod operator'
          "
        >
          <cancelRes
            :participant="participant"
            :session="session"
            @CancelReservation="CancelReservation"
          />
        </div>
      </tr>
      <div
        v-if="participant.ticket_id"
        class="d-flex d-md-none py-2 pl-3 pr-1"
        style="
          border-bottom: 1px solid #4e4222 !important;
          border-radius: 0% !important;
        "
      >
        <div class="d-flex justify-content-between align-items-center w-100">
          <div class="d-flex">
            <span
              v-if="participant.checked_in"
              style="display: flex; justify-content: center"
            >
              <img
                :src="getIcon('green-dot.svg')"
                alt="green-icon"
                class="mr-2"
              />
            </span>
            <span v-else style="display: flex; justify-content: center">
              <img
                :src="getIcon('yellow-dot.svg')"
                alt="yellow icon"
                class="mr-2"
              />
            </span>
            <div>
              <p class="m-0 font-weight-bold font-16">
                {{ participant.first_name }} {{ participant.last_name }}
              </p>
              <p class="m-0 font-weight-normal font-12">
                {{ participant.role ? participant.role + " |" : "" }}
                {{ participant.checked_in ? "Checked in" : "Reserved" }}
              </p>
            </div>
          </div>
          <div
            class="mr-3"
            v-if="
              $store.state.role == 'admin' ||
              $store.state.role == 'pod operator'
            "
          >
            <cancelRes
              :participant="participant"
              :session="session"
              @CancelReservation="CancelReservation"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import cancelRes from "./cancelReservation.vue";
import ClickOutside from "vue-click-outside";
import $ from "jquery";
import { API } from "aws-amplify";
export default {
  name: "pod participants",
  directives: {
    ClickOutside,
  },
  data() {
    return {
      participants: [],
      openCancelBtn: false,
      orderId: "",
    };
  },
  components: {
    cancelRes,
  },
  props: {
    session: Object,
  },
  async mounted() {
    var retundata = [];
    await API.get(
      "asulink",
      `/scheduler/session/${this.session.session_id}/orders`
    ).then((orders) => {
      retundata = orders;
    });
    this.participants = retundata;
  },
  methods: {
    getIcon(img) {
      return require("@/assets/icons/" + img);
    },
    hide() {
      this.openCancelBtn = false;
    },
    async getOrders() {
      await API.get(
        "asulink",
        `/scheduler/session/${this.session.session_id}/orders`
      ).then((orders) => {
        this.participants = orders;
      });
    },
    CancelReservation(data) {
      this.$store.state.cancelSession = null;
      this.$store.state.CancelReservation = data;
      this.$nextTick(() => {
        $("#cancel-modal").modal();
      });
    },
    cancelOrderMenu(data) {
      this.orderId = data;
      this.openCancelBtn = !this.openCancelBtn;
    },
    async getUserDetails(ticket_id) {
      let orderDetails;
      await API.get("asulink", `/customer/ticket/id/ASU00001/${ticket_id}`)
        .then((response) => {
          orderDetails = response;
        })
        .catch((err) => {
          window.console.log("Error occured", err);
        });
      return orderDetails;
    },
  },
};
</script>

<style>
.cancel-reservation {
  top: 0%;
  position: absolute;
  right: 90%;
  min-width: 130px;
  padding: 5px;
  border: none !important;
  background: gray;
  color: #ffc627;
  z-index: 999;
  border-radius: 7% !important;
}
</style>